import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import { Landing, Agreements, } from 'pages'

import { LandingHeader, } from 'systems'

function App() {
  return (
    <Router>
      <LandingHeader />
      <Switch>
        <Route exact path='/'>
          <Landing />
        </Route>
        <Route exact path={[ '/termsofuse', '/privacy', ]}>
          <Agreements />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
