import React from 'react'
import styled from 'styled-components'
import { Parallax, } from 'react-parallax'
import { useMediaQuery, } from 'react-responsive'

import { colors, } from 'globals/constants'
import { SectionProps } from 'globals/types'

const Section1Container = styled.div`
  height: 1082px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 550px) {
    height: 100vh;
  }

  .content {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: row;
    position: relative;
    padding-top: 250px;

    @media only screen and (max-width: 550px) {
      padding-top: 0;
      height: auto;
    }

    .images--phone {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 250px;

      img {
        position: absolute;
        width: 339px;
        height: 666px;
      }
    }

    .headings {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-top: 80px;

      > div {
        font-size: 3.6em;

        &.slogan {
          font-size: 24px;
          line-height: 1.4em;
        }

        @media only screen and (max-width: 550px) {
          text-align: center;
          font-size: 3em;

          &.slogan {
            font-size: 2em;
            padding-left: 0;
          }
        }

        &.title {
          line-height: 69px;

          @media only screen and (max-width: 550px) {
            line-height: 45px;
            padding-left: 0;
          }
        }
      }
    }

    .download-buttons {
      display: flex;
      margin-top: 30px;
    }
  }
`

const PlatformDownloadButton = styled.button`
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  height: 79px;

  @media only screen and (max-width: 550px) {
    align-self: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &#app-store {
    margin-right: 20px;

    @media only screen and (max-width: 550px) {
      width: 191px;
      height: 65px;
    }
  }

  &#play-store {
    width: 260px;

    @media only screen and (max-width: 550px) {
      width: 190px;
      height: 58px;
    }
  }
`

function Section1(props: SectionProps) {
  const { id, } = props

  const isMobile = useMediaQuery({ query: '(max-width: 550px)', })

  const onDownloadClick = (platform: 'ios' | 'android') => {
    return () => {
      alert('준비중입니다.')
      /*
      if(platform === 'ios') {
        window.open('https://apps.apple.com/kr/app/평카인/id1535896640')
      } else {
        window.open('https://play.google.com/store/apps/details?id=kr.pcar')
      }
       */
    }
  }

  return (
    <Parallax
      renderLayer={
        _ => (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
          />
        )
      }
      blur={ 10 }
      bgImage={ require('../assets/images/section1_background.jpg') }
      strength={ 100 }>
      <Section1Container id={ id }>
        <div className='flex-container content'>
          {
            isMobile ? (
              null
            ) : (
              <div className='images--phone'>
                <img
                  alt='section1_phone_1'
                  src={ require('../assets/images/section1_phone_2.png') }
                  style={{ left: 160, bottom: 150, }}
                />
                <img
                  alt='section1_phone_2'
                  src={ require('../assets/images/section1_phone_1.png') }
                  style={{ left: -130, bottom: 143, }}
                />
              </div>
            )
          }
          <div className='headings'>
            <div
              className='slogan'
              style={{ fontWeight: 400, color: 'rgb(160, 160, 160)' }}>
              차량의 진단평가와 검수, 탁송까지 브릿카에서<br/>
              높은 퀄리티의 서비스를 받아보실 수 있습니다
            </div>
            <div
              className='title'
              style={{ fontWeight: 600, color: 'white', marginTop: 30, }}>
              <span style={{ color: colors.PRIMARY, }}>브릿카</span>를 설치하고<br />
              서비스를 받아보세요
            </div>
            <div className='download-buttons'>
              <PlatformDownloadButton
                id='app-store'
                onClick={ onDownloadClick('ios') }>
                <img
                  alt='app-store'
                  src={ require('../assets/images/app-store.png') }
                />
              </PlatformDownloadButton>
              <PlatformDownloadButton
                onClick={ onDownloadClick('android') }
                id='play-store'>
                <img
                  alt='google-play'
                  src={ require('../assets/images/google-play.png') }
                  style={{ width: '100%', height: '100%', }}
                />
              </PlatformDownloadButton>
            </div>
          </div>
        </div>
      </Section1Container>
    </Parallax>
  )
}

export default Section1
