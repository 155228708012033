import qs from 'qs'
import React from 'react'
import media from 'styled-media-query'
import styled from 'styled-components'
import { useLocation, } from 'react-router-dom'

import { H1, utils, } from 'styles'

interface Props {

}

interface AgreementsContainerProps {
  withoutHeader: boolean
}

const AgreementsContainer = styled.div`
  padding-top: ${ (props: AgreementsContainerProps) => props.withoutHeader ? '24px' : '128px' };
  padding-bottom: 32px;
  ${ utils.flex('column', 'flex-start', 'center') }

  .container {
    width: 1200px;

    ${
      media.lessThan('1200px')`
        width: auto;
      `
    }

    @media only screen and (max-width: 550px) {
      width: 80%;
    }
  }
`

const AgreementContainer = styled.div`
  font-size: 15px;

  table, th, td {
    border-collapse: collapse;
    border: 1px solid black;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  th, td {
    padding: 12px;
  }
`

function Agreements(props: Props) {
  const location = useLocation()

  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const withoutHeader = params['hide-header'] === '1'

  return (
    <AgreementsContainer withoutHeader={ withoutHeader }>
      <div className='container'>
        {
          !withoutHeader ? (
            <H1>
              { location.pathname === '/privacy' ? '개인정보 처리방침' : '서비스 이용 약관' }
            </H1>
          ) : null
        }
        {
          location.pathname === '/termsofuse' ? (
            <AgreementContainer
              dangerouslySetInnerHTML={{ __html: require('./assets/html/termsofuse.html') }}
            />
          ) : null
        }
        {
          location.pathname === '/privacy' ? (
            <AgreementContainer
              dangerouslySetInnerHTML={{ __html: require('./assets/html/privacy.html') }}
            />
          ) : null
        }
      </div>
    </AgreementsContainer>
  )
}

export default Agreements
