import React from 'react'
import media from 'styled-media-query'
import styled from 'styled-components'
import { Link, } from 'react-router-dom'
import { useMediaQuery, } from 'react-responsive'

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 52px;
  padding-bottom: 57px;
  background-color: rgb(248, 248, 248);

  @media only screen and (max-width: 550px) {
    padding-top: 32px;
    padding-bottom: 43px;
    padding-left: 20px;
  }

  .content {
    display: flex;

    a, div {
      text-decoration: none;
      font-size: 1.2em;
      color: rgb(57, 57, 57);

      ${
        media.lessThan('550px')`
          font-size: 1em;
        `
      }
    }

    &.agreements {
      >:first-child {
        margin-right: 36px;

        @media only screen and (max-width: 550px) {
          margin-right: 30px;
        }
      }
    }

    &.business-info {
      margin-top: 30px;

      @media only screen and (max-width: 550px) {
        margin-top: 15px;
      }

      > div {
        margin-right: 20px;

        @media only screen and (max-width: 550px) {
          margin-right: 15px;
        }
      }
    }

    &.business-info2 {
      margin-top: 20px;

      @media only screen and (max-width: 550px) {
        margin-top: 15px;
      }

      >:first-child {
        margin-right: 40px;

        @media only screen and (max-width: 550px) {
          margin-right: 15px;
        }
      }
    }

    &.copyright {
      margin-top: 30px;
    }
  }
`

function Footer() {
  const isMobile = useMediaQuery({ query: '(max-width: 550px)', })

  return (
    <FooterContainer>
      <div className='container'>
        <div className='content agreements'>
          <Link to='privacy'>
            개인정보 처리방침
          </Link>
          <Link to='termsofuse'>
            이용약관
          </Link>
        </div>
        <div className='content business-info'>
          {
            isMobile ? (
              <>
                <div style={{ fontWeight: 'bold', }}>
                  주식회사 브릿카
                </div>
                <div>
                  대표: 문재영
                </div>
              </>
            ) : (
              <>
                <div style={{ fontWeight: 'bold', }}>
                  주식회사 브릿카
                </div>
                <div>
                  대표: 문재영
                </div>
                <div>
                  주소: 경기도 수원시 팔달구 갓매산로 51, 601-283호
                </div>
                <div>
                  사업자등록번호: 165-88-03506
                </div>
              </>
            )
          }
        </div>
        {
          isMobile ? (
            <div className='content business-info'>
              사업자등록번호: 165-88-03506
            </div>
          ) : null
        }
        {
          isMobile ? (
            <div className='content business-info'>
              <div>
                주소: 경기도 수원시 팔달구 갓매산로 51, 601-283호
              </div>
            </div>
          ) : null
        }
        <div className='content business-info2'>
          {
            /*
              <div>
                Tel: 1877-2647
              </div>
            */
          }
          <div>
            Email: admin@bridcar.com
          </div>
        </div>
        <div className='content copyright'>
          <div>
            Copyright 2024     주식회사 브릿카 all rights reserved
          </div>
        </div>
      </div>
    </FooterContainer>
  )
}

export default Footer
