import { css, } from 'styled-components'

type FlexAlignments = 'flex-start' | 'flex-end' | 'center'
type FlexJustifyContentOnlyAlignments = 'space-between' | 'space-around'

type FontWeight = 'thin' | 'regular' | 'medium' | 'bold'

interface SizeUnitProps {
  sizeUnit?: string
}

export interface CommonMarginProps extends SizeUnitProps {
  marginTop?: number
  marginBottom?: number
  marginRight?: number
  marginLeft?: number
}

export interface CommonTextProps extends SizeUnitProps, CommonMarginProps {
  size?: number
  color?: string
  weight?: FontWeight
  lineHeight?: number
  textAlign?: 'left' | 'center' | 'right'
}


export const wh100 = css`
  width: 100%;
  height: 100%;
`

export const absolutePosition = css`
  position: absolute;
  top: 0;
  left: 0;
`

export const flex = (
  direction: 'row' | 'column',
  justifyContent: FlexAlignments | FlexJustifyContentOnlyAlignments = 'flex-start',
  alignItems: FlexAlignments = 'flex-start'
  ) => css`
  display: flex;
  flex-direction: ${ direction };
  justify-content: ${ justifyContent };
  align-items: ${ alignItems };
`

const getFontWeight = (weight?: FontWeight) => {
  switch(weight) {
    case 'thin':
      return '200'
    case 'medium':
      return '500'
    case 'bold':
      return '700'
    case 'regular':
    default:
      return '400'
  }
}

export const commonMarginStyle = (params: CommonMarginProps) => css`
  ${ () => params.marginTop ? `margin-top: ${ params.marginTop }${ params.sizeUnit || 'px' }` : `` };
  ${ () => params.marginBottom ? `margin-bottom: ${ params.marginBottom }${ params.sizeUnit || 'px' }` : `` };
  ${ () => params.marginLeft ? `margin-left: ${ params.marginLeft }${ params.sizeUnit || 'px' }` : `` };
  ${ () => params.marginRight ? `margin-right: ${ params.marginRight }${ params.sizeUnit || 'px' }` : `` };
`

export const commonTextStyle = (params: CommonTextProps) => css`
  color: ${ params.color };

  ${ `font-weight: ${ getFontWeight(params.weight) }`};
  ${ () => params.size ? `font-size: ${ params.size }${ params.sizeUnit || 'px' }` : `` };
  ${ () => params.lineHeight ? `line-height: ${ params.lineHeight }${ params.sizeUnit || 'px' }` : `` };
  ${ () => params.textAlign ? `text-align: ${ params.textAlign }` : `` };

  ${ commonMarginStyle(params) }
`

