import qs from 'qs'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Transition, } from 'react-transition-group'
import React, { useState, useMemo, useEffect, } from 'react'
import { useHistory, useLocation, useParams, Link, } from 'react-router-dom'

import { Span, utils, } from 'styles'

interface Props {
  fixed?: boolean
}

interface LandingHeaderProps {
  fixed: boolean
  state?: string
  black?: boolean
}

interface LogoContainerProps {
  to: string
}

const Header = styled.header<LandingHeaderProps>`
  width: 100%;
  height: 80px;
  z-index: 101;
  top: 0;
  left: 0;
  position: absolute;
  ${ utils.flex('row', 'center', 'center') }

  ${
    (props: LandingHeaderProps) => {
      if(props.black) {
        return `
          background-color: rgb(0, 0, 0);
        `
      }

      switch(props.state) {
        case 'entering':
          return `
            position: fixed;
            opacity: 0;
          `
        case 'entered':
          return `
            position: fixed;
            opacity: 1;
            transition: 0.5s;
            background-color: rgba(0, 0, 0, 0.8);
          `
      }
    }
  }
`

const HeaderContent = styled.div`
  width: 1200px;
  ${ utils.flex('row', 'flex-start', 'center') }

  ${
    media.lessThan('1025px')`
      width: 100%;
      padding-left: 2em;
    `
  }

  .container--menu {
    flex: 16;
    ${ utils.flex('row', 'flex-end', 'center') }

    > a {
      margin-top: .1em;
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const LogoContainer = styled(Link)<LogoContainerProps>`
  text-decoration: none;
  ${ utils.flex('row', 'flex-start', 'center') }

  .logo {
    width: 60px;
    height: 60px;
    margin-right: .3em;
  }

  .wording--logo {
    margin-top: .1em;
    font-weight: bold;
  }
`

function registerScrollingEvent(onScroll: Function) {
  const handleScroll = (e: any) => {
    let scroll

    scroll = e.srcElement.scrollingElement?.scrollTop

    if(!scroll) {
      scroll = e.srcElement.documentElement.scrollTop
    }

    onScroll(scroll)
  }

  window.addEventListener('scroll', handleScroll)

  return handleScroll
}

function LandingHeader(props: Props) {
  const location = useLocation()
  const history = useHistory()

  const [ headerScroll, setHeaderScroll, ] = useState<number>(window.pageYOffset)

  useEffect(() => {
    let handleScroll: any

    handleScroll = registerScrollingEvent(setHeaderScroll)

    history.listen((location, action) => {
      window.removeEventListener('scroll', handleScroll)
      handleScroll = registerScrollingEvent(setHeaderScroll)

      setHeaderScroll(0)
    })

    return () => window.removeEventListener('scroll', handleScroll)
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const headerFixed = useMemo<boolean>(
    (): boolean => {
      if(headerScroll > 200) {
        return true
      } else if(headerScroll <= 200) {
        return false
      }

      return false
    },
    [headerScroll]
  )

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  if(query['hide-header'] === '1') {
    return null
  }

  return (
    <Transition
      in={ location.pathname === '/' && headerFixed }
      appear={ location.pathname === '/' && headerFixed }
      timeout={ 200 }>
      {
        (state => {
          return (
            <Header
              state={ state }
              fixed={ headerFixed }
              black={ location.pathname !== '/' }>
              <HeaderContent>
                <LogoContainer to='/'>
                  <img
                    className='logo'
                    src={ require('assets/images/logo_trans.png') }
                    alt='logo'
                  />
                  <Span size={ 1.8 } sizeUnit='em' color='white' className='wording--logo'>
                    브릿카
                  </Span>
                </LogoContainer>
              </HeaderContent>
            </Header>
          )
        })
      }
    </Transition>
  )
}

export default LandingHeader
