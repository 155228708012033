import styled from 'styled-components'

import { CommonMarginProps, commonMarginStyle, } from '../utils'

interface ImgProps extends CommonMarginProps {
  width?: number | string
  height?: number | string
  sizeUnit?: string
  objectFit?: string
}

export default styled.img`
  ${
    (props: ImgProps) => props.width ?
      (
        typeof props.width === 'string' ? props.width : `
          width: ${ props.width }${ props.sizeUnit || 'px' }
        `
      ) : ''
  };
  ${
    (props: ImgProps) => props.height ?
      (
        typeof props.height === 'string' ? props.height : `
          height: ${ props.height }${ props.sizeUnit || 'px' }
        `
      ) : ''
  };

  object-fit: ${ (props: ImgProps) => props.objectFit };

  ${ (props: ImgProps) => commonMarginStyle(props) }
`
