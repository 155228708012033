import styled from 'styled-components'
import media from 'styled-media-query'
import Scrollspy from 'react-scrollspy'
import React, { useState, } from 'react'

import { LandingHeader, } from 'systems'

import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Footer from './Footer'

const LandingContainer = styled.div`
  overflow: hidden;
  font-size: 13px;

  @media screen and (max-width: 1800px) {
    font-size: 12px;
  }

  @media screen and (max-width: 550px) {
    font-size: 10px;
  }

  .container {
    width: 1200px;

    ${
      media.lessThan('1200px')`
        width: auto;
      `
    }

    @media only screen and (max-width: 550px) {
      width: 100%;
    }
  }

  .flex-container {
    flex-basis: 1200px;
    flex-shrink: 0;

    @media only screen and (max-width: 550px) {
      flex: 1;
    }
  }
`

function App() {
  const [ currentScrollElementId, setCurrentScrollElementId, ] = useState()

  const onScrollUpdate = (element: HTMLElement) => {
    if(element) {
      setCurrentScrollElementId(element.id)
    }
  }

  return (
    <LandingContainer>
      {
        /*
        <Scrollspy
          offset={ -500 }
          componentTag='main'
          onUpdate={ onScrollUpdate }
          currentClassName='current-section'
          items={[ 'section1', 'section2', 'section3', 'section4' ]}>
          <Section1 id='section1' focused={ currentScrollElementId === 'section1' } />
          <Section2 id='section2' focused={ currentScrollElementId === 'section2' } />
          <Section3 id='section3' focused={ currentScrollElementId === 'section3' } />
          <Section4 id='section4' focused={ currentScrollElementId === 'section4' } />
        </Scrollspy>
        */
      }
      <Scrollspy
        offset={ -500 }
        componentTag='main'
        onUpdate={ onScrollUpdate }
        currentClassName='current-section'
        items={[ 'section1', ]}>
        <Section1 id='section1' focused={ currentScrollElementId === 'section1' } />
      </Scrollspy>
      <Footer />
    </LandingContainer>
  )
}

export default App
